<template>
  <div  class="minWidth1000">
    <custom-actions :panel="false">
      <template #header>
        <el-button type="primary" @click="$router.push('/system/role/add')">新增角色</el-button>
      </template>
    </custom-actions>

    <el-card shadow="never" class="mt10">
      <el-table stripe size="mini" border v-loading="loading" :data="tableData" row-key="roleId" :tree-props="{children: 'childNode'}">
        <el-table-column label="角色名称" prop="roleName"></el-table-column>
        <el-table-column label="操作">
          <template v-slot="{ row }">
            <el-button type="text" @click="$router.push('/system/role/edit?roleId=' + row.roleId)">编辑</el-button>
            <el-button type="text" @click="deleteMaterial(row.materialId)" class="del-btn">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

  </div>
</template>

<script>
export default {
  name: 'RoleManage',
  data () {
    return {
      tableData: [],
      loading: false
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$axios.get(this.$apis.system.selectSysRoleByList).then(res => {
        this.tableData = res
      })
    },
    deleteRow (roleId) {
      this.$confirm('确认删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.system.deleteSysRole, {
          roleId
        }).then(() => {
          this.loadData()
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
